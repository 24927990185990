import React from "react"
import { silentAuth, isBrowser, logout } from "./src/utils/auth"
import PropTypes from 'prop-types';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorPage from "./src/_pages/500";
import "./src/styles/site.scss";

const axios = require('axios').default;

// This will reset the session on the SPA if the user is already logged in
class SessionCheck extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  handleCheckSession = (err) => {
    if(err) {
      const Sentry = isBrowser ? window.Sentry : {};
      Sentry.captureMessage(err);
      console.log(err);
      logout();
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession);
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      console.log("exec global axios...");
      if (error.response && error.response.status && 401 === error.response.status) {
        // force logout
        const Sentry = isBrowser ? window.Sentry : {};
        Sentry.captureMessage(error);
        logout();
      } 
      return error;
    });
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>
          <ToastContainer position="bottom-left" autoClose={3500} />
          {this.props.children}
        </React.Fragment>
      )
    )
  }
}

class ErrorCheck extends React.Component {
  // https://www.gatsbyjs.org/packages/gatsby-plugin-sentry/
  constructor(props) {
    super(props);
    this.state = { error: null };
  }


  componentDidCatch(error, errorInfo) {
    const Sentry = isBrowser ? window.Sentry : {};
    this.setState({ error });
    console.log(errorInfo);
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />
    } else {
      return this.props.children;
    }
  }
}

export const wrapRootElement = ({ element }) => {
  return <ErrorCheck><SessionCheck>{element}</SessionCheck></ErrorCheck>
}

wrapRootElement.propTypes = {
  element: PropTypes.object.isRequired,
}

ErrorCheck.propTypes = {
  children: PropTypes.object.isRequired
}
