const rules = {
    'etho-super-user': {
        static: [
            'admin-page:visit',
            'members:list',
            'members:create',
            'members:delete',
        ],
        dynamic: {
            "members:edit": ({userId, memberId}) => {
                if(!userId || !memberId) return false;
                return userId === memberId;
            }
        },
    },
    'etho-admin': {
        static: [
            'admin-page:visit',
        ],
        dynamic: {
            "members:edit": ({userId, memberId}) => {
                if(!userId || !memberId) return false;
                return userId === memberId;
            }
        },
    },
    'etho-member': {
        static: [
            'profile-page:visit',
        ]
    }
};

export default rules;
