import { Link } from "gatsby";
import React from "react";
import { logout, isAuthenticated, isBrowser, getProfile, loginPage } from "../../utils/auth"
import Can from "../../utils/can";

function HeaderLinks() {
    let redirectUrl = isBrowser ? encodeURIComponent(window.location.pathname + window.location.search) : "/";
    return (
        <>
            {isAuthenticated() ? (
                <>
                    <Can
                        role={getProfile().roles}
                        perform="admin-page:visit"
                        yes={() => (
                            <Link
                                className=""
                                key={"admin"}
                                to={`/app/admin`}
                            >Admin area</Link>
                        )}
                        no={() => null}
                    />
                    <a
                        className="md:border-l md:pl-4 border-gray-400"
                        href="/"
                        onClick={e => {
                            logout();
                            e.preventDefault();
                        }}
                    >Sign out</a>
                </>
            ) :
                (
                    <a href="#"
                        className="block md:inline-block mt-4 md:mt-0 md:ml-3 no-underline text-blue-500"
                        key={"login"}
                        onClick={() => loginPage(redirectUrl)}
                        // to={`/app/login?redirect=${redirectUrl}`}
                    >Sign in</a>
                )
            }
        </>
    )
}
export default HeaderLinks;
