import React from "react";
import PropTypes from "prop-types";

class AppLayoutContainer extends React.Component {
  render() {
    return (
      <div className="px-4 md:px-8 py-4">
        {this.props.children}
      </div>
    );
  }
}

AppLayoutContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppLayoutContainer;
