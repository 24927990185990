import React from "react";

function Footer() {
  return (
    <footer className="bg-white p-5 text-base">
      <div className="text-center">
        © Etho Informatics Ltd { (new Date()).getFullYear() } | <a href="mailto:info@etho.co.uk">info@etho.co.uk</a> | <a href="https://www.iubenda.com/privacy-policy/76866083">Privacy Policy</a> | <a href="https://www.iubenda.com/terms-and-conditions/76866083">Terms</a>
      </div>
    </footer>
  );
}

export default Footer;
