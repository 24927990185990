import React from "react";
import PropTypes from "prop-types";

class Title extends React.Component {
  render() {
    // Add logic for props, types etc here
    const border = this.props.border || false;
    const additionalClasses = this.props.className || "";
    var containerClasses = this.props.containerClassName || "";

    {
      border == true && (
        containerClasses = [containerClasses, "border-b pb-6 mb-3"].join(" ")
      )
    }

    return (
      <div
        className={[
          "titleSection pt-5 pb-4 px-8",
          containerClasses,
        ].join(" ")}>
        <span
          className={[
            "block h1 text-center",
            additionalClasses,
          ].join(" ")}>
          {this.props.children}
        </span>
      </div>
    );
  }
}

Title.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  border: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Title;
