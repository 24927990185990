import React from "react";
import PropTypes from "prop-types";

class AppLayout extends React.Component {
  render() {
    return (
      <section className="block bg-gray-100 py-10 h-full">
        <div className="container px-6 md:px-8 lg:px-0 block mx-auto">
          <div className="w-full lg:w-4/5 mx-auto">
            <div className="bg-white shadow-sm border rounded-sm">
              {this.props.children}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

AppLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default AppLayout;
