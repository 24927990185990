import { Link } from "gatsby";
import React, { useState } from "react";
import ethoLogo from "../../images/logo-etho.svg";
import { getProfile } from "../../utils/auth";
import Can from "../../utils/can";
import HeaderLinks from "../components/headerLinks";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="shadow z-10">
      <div className="flex flex-wrap flex-row py-4 px-6">
        {/* Empty div to shift logo center */}
        <div className="flex flex-1 justify-around items-center"></div>

        {/* Logo image */}
        <div className="flex flex-1 justify-around items-center">
          <div className="flex items-center">
            <Link className="flex items-center no-underline text-white" to="/">
              <img
                alt="Etho Logo"
                className="fill-current h-8 w-18"
                src={ethoLogo}
              />
            </Link>

            <button
              className="flex md:hidden border border-white items-center px-3 pt-1 text-gunmetal-500"
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className="fill-current h-6 w-6"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>

        {/* Right nav */}
        <div className="flex flex-1 justify-around items-center">
          <nav className="headerNav w-full text-right">
            <HeaderLinks />
          </nav>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } mobileNav bg-white px-8 py-4`}
      >
        <ul className="text-center">
          <Can
            role={getProfile().roles}
            perform="admin-page:visit"
            yes={() => (
              <>
                <li>
                  <Link to="/app/admin">Dashboard</Link>
                </li>
                <li>
                  <Link to="/app/admin/networks/">Networks</Link>
                </li>
                <li>
                  <Link to="/app/admin/organisations/">Organisations</Link>
                </li>
                <li>
                  <Link to="/app/admin/locations">Locations</Link>
                </li>
                <li>
                  <Link to="/app/admin/users/">Users</Link>
                </li>
              </>
            )}
            no={() => null}
          />
          <HeaderLinks />
        </ul>
      </div>
    </header>
  );
}

export default Header;
