import { navigate } from "gatsby";
import auth0 from "auth0-js";

export const isBrowser = typeof window !== "undefined";

let auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.AUTH0_CLIENTID,
      redirectUri: process.env.AUTH0_CALLBACK,
      responseType: "token id_token",
      scope: "openid profile email code",
      audience: process.env.AUTH0_AUDIENCE,
    })
  : {};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
};

let user = {};

export const isAuthenticated = () => {
  console.log("Checking isLoggedIn...");
  if (!isBrowser) {
    console.warn("Not a browser");
    return;
  }
  let isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  console.log(isLoggedIn);
  console.log(localStorage.getItem("isLoggedIn"));
  return isLoggedIn;
};

export const login = (redirect) => {
  if (!isBrowser) {
    return;
  }
  if (redirect) {
    // ensure redirect ends in slash for safari
    if (!redirect.endsWith("/")) {
        redirect = redirect + "/";
    }
    console.log(`Redirect URL: ${redirect}`);
    auth = new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN,
      clientID: process.env.AUTH0_CLIENTID,
      redirectUri: `${process.env.AUTH0_CALLBACK}?redirect=${redirect}`,
      responseType: "token id_token",
      scope: "openid profile email code",
      audience: process.env.AUTH0_AUDIENCE,
    });
  }
  console.log(auth.baseOptions.redirectUri);
  //auth.authorize();
};

export const loginPage = (redirectUrl) => {
  redirectUrl ? redirectUrl : "/";
  let redirectUri = redirectUrl
    ? `${process.env.AUTH0_CALLBACK}?redirect=${redirectUrl}`
    : process.env.AUTH0_CALLBACK;
  // temporarily use ath0 universal login
  console.log(process.env.AUTH0_DOMAIN);
  console.log(redirectUri);
  console.log(redirectUrl);
  console.log(process.env.AUTH0_AUDIENCE);
  var auth = new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENTID,
    redirectUri: redirectUri,
    responseType: "token id_token",
    scope: "openid profile email code",
    audience: process.env.AUTH0_AUDIENCE,
  });
  auth.authorize();
};

const setSession = (redirect = null, cb = () => {}) => (err, authResult) => {
  if (err) {
    // Trigger log out
    console.log("Error being thrown...");
    console.log(err);
    const Sentry = isBrowser ? window.Sentry : {};
    Sentry.captureMessage(err);
    logout({ returnTo: process.env.DOMAIN });
    return;
    //logout(() => navigate("/"));
    // Throw error
    // throw err;
  }
  console.log("Safari, why?!");
  console.log(authResult);
  if (authResult) {
    console.log(authResult.accessToken);
    console.log(authResult.idToken);
  }
  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    tokens.accessToken = authResult.accessToken;
    tokens.idToken = authResult.idToken;
    tokens.expiresAt = expiresAt;
    user = authResult.idTokenPayload;
    localStorage.setItem("isLoggedIn", true);
    if (redirect) {
      let nestedRedirect = getParameterByName('redirect', redirect);
      if(nestedRedirect) {
        console.log(`Setting session with redirect ${nestedRedirect}`);
        navigate(nestedRedirect);
      } else {
        navigate(redirect);
      }
    } else {
      console.log("Setting session with callback");
      //navigate("/");
      cb();
    }
    cb();
  } else {
    navigate(redirect);
  }
};

export const handleAuthentication = (redirect = null) => {
  console.log(`handleAuthentication(${redirect})`);
  if (!isBrowser) {
    console.log("Not a browser...");
    return;
  }
  auth.parseHash(setSession(redirect));
};

export const getProfile = () => {
  let roles = user["http://etho.co.uk/roles"];
  let userProfile = {
    ...user,
    roles: roles,
  };
  return userProfile;
};

export const getTokens = () => {
  return tokens;
};

export const silentAuth = (callback) => {
  if (!isAuthenticated()) {
    console.warn("User not authenticated");
    return callback();
  }
  auth.checkSession({}, setSession(null, callback));
};

export const logout = (logoutOptions = {}) => {
  let options = {
    ...logoutOptions,
    clientID: process.env.AUTH0_CLIENTID,
    returnTo: process.env.DOMAIN,
  };
  localStorage.setItem("isLoggedIn", false);
  auth.logout(options);
};

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
