import rules from "../static/rbac-rules";

const check = (rules, roles, action, data) => {
    roles = roles || [];
    let hasPermissions = roles.map(role => {
        const permissions = rules[role];
        if (!permissions) {
            // role is not present in the rules
            //console.log("role is not present in the rules");
            return false;
        }

        const staticPermissions = permissions.static;

        if (staticPermissions && staticPermissions.includes(action)) {
            // static rule not provided for action
            //console.log("static rule not provided for action");
            return true;
        }

        const dynamicPermissions = permissions.dynamic;

        if (dynamicPermissions) {
            const permissionCondition = dynamicPermissions[action];
            if (!permissionCondition) {
                // dynamic rule not provided for action
                //console.log("dynamic rule not provided for action");
                return false;
            }

            //console.log("exec. permission condition.");
            return permissionCondition(data);
        }
        //console.log("default fail.");
        return false;
    });
    return hasPermissions.includes(true);
};

const Can = props =>
      check(rules, props.role, props.perform, props.data)
      ? props.yes()
      : props.no();

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;
