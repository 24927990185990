
import React from "react";

import AppLayout from "../views/containers/appLayout";
import AppLayoutContainer from "../views/containers/AppLayoutContainer";
import Layout from "../views/components/layout";
import SEO from "../views/components/seo";
import Title from "../views/components/title";

import NotFoundIllustration from "../images/icon-500.svg";

function ErrorPage() {
  return (
    <Layout>
      <SEO title="500: Not found" />
      <AppLayout>
          <Title border={true}>500 error</Title>
          <AppLayoutContainer>
            <img
              alt="Ghost getting abducted by aliens"
              className="block mx-auto w-20 mb-6"
              src={NotFoundIllustration}
            />
            <p className="text-center mb-4 font-regular">Internal server error</p>
            <p className="text-center mb-4 font-regular">Try refreshing the page or <a href={`mailto:info@etho.co.uk`} className="font-bold">contact us</a> if it persists</p>
          </AppLayoutContainer>
      </AppLayout>
    </Layout>
  );
}

export default ErrorPage;
